import './App.css';
import Saludo from './components/Saludo';

function App() {
  return (
    <div className ="App">
      <Saludo nombre="Daniel G" />
    </div>
  );
}

export default App;
